import React, { useState } from "react";
import PersonalDetails from "../sections/PersonalDetails";
import FIRDetails from "../sections/FIRDetails";
import OffenseDetails from "../sections/OffenseDetails";
import ChargesheetCaseStage from "../sections/ChargeSheet";
import "./CrPC482Questionnaire.css";
import EvidenceDetails from "../sections/EvidenceDetails";
import BailAndCustody from "../sections/BailAndCustody";
import GroundsForQuashment from "../sections/GroundsForQuashment";

export default function CrPC482Questionnaire() {
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedPetition, setGeneratedPetition] = useState("");

  const [formData, setFormData] = useState({
    personalDetails: {},
    firDetails: {},
    offenseDetails: {},
    ChargesheetCaseStage: {},
    EvidenceDetails: {},
    BailAndCustody: {},
    GroundsForQuashment: {},
  });

  const updateSectionData = (section, data) => {
    console.log(`Updating ${section} with data:`, data);
    setFormData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };
  


  const handleGenerateClick = async () => {
    try {
      setIsGenerating(true);

      // Collect data for the prompt
      const collectedData = {
        personalDetails: formData.personalDetails,
        firDetails: formData.firDetails,
        offenseDetails: formData.offenseDetails,
        chargesheetCaseStage: formData.ChargesheetCaseStage,
        evidenceDetails: formData.EvidenceDetails,
        bailAndCustody: formData.BailAndCustody,
        groundsForQuashment: formData.GroundsForQuashment,
      };

      const modelDraft = `
      BEFORE THE HON’BLE HIGH COURT OF __________________________________,
AT _______________
CRIMINAL WRIT JURISDICTION
Criminal Writ Petition No. _____ of 2024
Matter pertains to District ____________

IN THE MATTER OF Article 226 of Constitution of India;
AND
IN THE MATTER OF Section 528 of The Bharatiya Nagarik Suraksha Sanhita, 2024;
AND
IN THE MATTER OF First Information Report bearing CR No. _______ of _______ registered with the ______________ Police Station, _________________, being Respondent No. 01 herein;
AND	
In the matter of CC No. ___________ of ___________ pending before the Ld. __________________________, at _________________.


1.	____________________, Aged _____ Years
S/O ________________________________
R/O________________________________
PETITIONER(s)
VERSUS
1.	STATE/ UT of ________________________
Through Police Station _________________
2.	____________________________________, 
S/O_________________________________
R/O_________________________________
RESPONDENT(s)


THE HUMBLE PETITION OF THE PETITIONERS ABOVENAMED.
MOST RESPECTFULLY SHOWETH:

1.	That the petitioners are law-abiding adult citizen of India and are presently residing at ____________________________________. The Petitioner(s) are the Accused in FIR No______ of 2024 registered with the ________________ Police Station _________________
2.	That the Respondent No.1 is the State which is responsible for maintaining law and order in the State/ UT of ______________________. Respondent No.2 is the original Complainant in the FIR bearing CR No. ________________ under challenge dated ________________ filed against the Petitioners.
3.	That by way of present petition, the Petitioners are seeking quashing of FIR No. _______of ______ registered at ______________ Police Station __________ u/s _____ of the Indian Penal Code, 1860 and Section 22 and 23 of the/ Bharatiya Nyaya Sanhita, 2024 & ( applicable if booked under any special law).


ISSUES INVOLVED:

4.	The Petitioners have approached this Hon’ble High Court by way of Writ Petition in its writ jurisdiction under Article 226 of Constitution of India and in its inherent jurisdiction under Section 482 of Code of Criminal Procedure for quashing of FIR No. ______ of ______ registered by ____________ Police Station, _______________.







BRIEF FACTS OF THE CASE:

5.	The Respondent No.1 had registered an FIR bearing No. _______of ______ dated __________ at the instance of Respondent No.2 – Mr. ________________ at _______________ Police Station, __________________. It is submitted that the Petitioners have been wrongfully, mischievously, with ulterior motives been named in the Impugned FIR; or
The Respondent No. 01 had The Respondent No.1 had registered an FIR bearing No. _______of ______ dated __________ at the instance of ___________ Judicial Magistrate by an Order passed under Section 156(3) Cr. P.C dated: ________.
(Copy of FIR No. ______/202_ dated _________ registered at Police Station ___________ is annexed herewith and marked as ANNEXURE ‘P-1’)
6.	The Respondent Nio. 02/ Complainant in the abovesaid FIR alleges that (the facts which are the part of the complaint, alleged offence by the complainant).
7.	 That on receipt of the information regarding the alleged offence and upon the careful perusal of the complaint, the Respondent No. 01 registered an FIR bearing CR No. _____ of 202_ dated ________, against the present petitioners/ accused person(s) *(it is pertinent to mention here, whether the Police registered the FIR directly or proceed first with any kind of Inquiry)
8.	That the Challan was presented and the case is pending trial before the Ld. _________ Magistrate on dated: _________, bearing Challan Case No. __________ of 202_. The stage of the CC Case is ____________, and the accused is appearing before the Ld. ________ Magistrate for all the date of hearings. *(Mention if the accused is not presenting himself on every hearing)
9.	That the accused was granted bail by the Ld. _____________, Magistrate via Bail Order dated ___________, and since then the present petitioner(s)/ accused are enlarged on bail.
(The Bail Order passed by the Ld. ___________ Magistrate dated ________ is annexed herein as ANNEXURE P-II)
10.	Being aggrieved of the aforesaid, the Petitioner(s) have approached this Hon’ble Court for quashing of the FIR bearing CR No. _____ of 202_ registered against them on following grounds which are as under:





GROUNDS

a.	BECAUSE the FIR registered against the present Petitioners does not prima facie constitute any offence based on the following pretexts.
b.	BECAUSE there is no material document or evidence present on record which substantiates the claim of the Respondent No.2/Complainant that the Petitioner(s) were involved in the alleged incident that took place. *(Explain how the material on record/ procured during investigation/ submitted during complainant is not incriminating to the extent of the petitioner(s)
c.	BECAUSE this Hon’ble Court must consider that the petitioner has every possibility of false implication in this matter as no independent witnesses have been examined by the committee. The entire case is filled with procedural infirmities, inconsistencies and embellishments. The complainant is miserably failed to establish its case against either of the accused on any count u/s _________ of IPC/ BNS/ *(Special Law if involved) for the following reasons:
	
*(Quote the substantial law under which the accused is booked and explain how the main ingredients are not fulfilled)
*(Quote the procedural law and explain how there were substantial inconsistencies and embellishments in the procedure followed by the Police)
*(Explain if there is any rivalry between the Complainant and Petitioner, to show that a Civil dispute is given a criminal colour)

d.	BECAUSE the Hon’ble Supreme Court/ High Court in: *(Quote the judgements/ precedents in favour of the petitioner as to how the present FIR be quashed based on a similar pretext)
e.	BECAUSE *(Explain how the judgements are applicable to the present case and how by not quashing the FIR would result in grave miscarriage of justice)

11.	That the Petitioner craves leave to add, alter and/or delete the averments in the Petition, if any, as and when necessary, with the kind permission of this Hon’ble Court.
12.	That Petitioners resides at the address given in the cause title of Petition and the FIR under challenge, has been registered at ____________ Police Station, ____________. Hence, this Hon’ble Court has jurisdiction to try and adjudicate upon the present petition.
13.	That the Petitioners submits that they have no alternative remedy but to approach this Hon’ble Court under Article 226 of the Constitution of India and Section 482 of the Criminal Procedure Code. The reliefs prayed for in the present petition if granted shall be adequate remedy for the grievances of the Petitioners.
14.	That the Petitioners submits that there has been no delay or laches in filing the present petition.
15.	That the Petitioners states that he has not filed any other or similar Petition, Application, Appeal or Revision either before this Hon’ble Court or before the Hon’ble Supreme Court of India or in any other Court pertaining to the subject matter, seeking similar reliefs as prayed in the present petition.


PRAYER
IT IS THEREFORE, RESPECTFULLY AND HUMBLY PRAYED THAT THIS HON'BLE COURT MAY KINDLY BE PLEASED TO: -
i)	Quash the FIR bearing CR No________/202_ registered at P.S________________, under Section _______ of the Indian Penal Code, 1860/ Bharatiya Nyaya Sanhita, 2024 and (any special law if applicable). other proceedings emanating out of the above said FIR; and/or
ii)	Pass any other or further order in favour of Petitioners which this Hon'ble Court may deem fit and proper in the facts and circumstances of the case in the interest of justice.
FOR THIS ACT OF KINDNESS, THE HUMBLE PETITIONER AS DULY BOUND SHALL EVER PRAY.
Dated:
Place:
PETITIONER(s)
Through Counsel:
_____________________________
_____________________________

      
`;

      // Build the prompt
      const prompt = `
      You are a legal expert AI. Generate a well-structured legal petition for quashing an FIR under Section 482 CrPC based on the following details. Ensure the language is formal, and include relevant legal points. 
      
      The petition should contain the following sections:
      
      1. **Cover Sheet**: 
         - Include the title of the High Court, case number, petitioner's name, respondent's name, and other relevant information.
         
      2. **Index**:
         - 1. Cover Sheet & Index
         - 2. Memo of Parties
         - 3. List of Dates & Events
         - 4. Main Petition
             1. Facts of the Case
             2. Grounds for Quashment
             3. Relief Sought
             4. Arguments
             5. Conclusion
         - 5. Affidavit for Petition
         - 6. Application for Interim Relief
         - 7. Affidavit for Interim Relief
      
      3. **Memo of Parties**: 
         - List all parties involved in the case, including their names, addresses, and roles (Petitioner, Respondent, etc.).
      
      4. **List of Dates & Events**: 
         - Provide the key dates and events related to the case.
      
      5. **Main Petition**:
         - **Facts of the Case**: Summarize the facts of the case based on the provided data.
         - **Grounds for Quashment**: Clearly outline the grounds on which the FIR should be quashed based on the evidence and law.
         - **Relief Sought**: Specify the relief the petitioners are seeking from the court.
         - **Arguments**: Present the legal arguments supporting the petitioner's case.
         - **Conclusion**: Conclude with the requested relief.
      
      6. **Annexures**: 
         - For each document referred to in the petition, include an annexure reference. For example, "A copy of FIR is annexed as Annexure I."
      
      **Details for the Petition**:

Personal Details:
${JSON.stringify(collectedData.personalDetails, null, 2)}

FIR Details:
${JSON.stringify(collectedData.firDetails, null, 2)}

Offense Details:
${JSON.stringify(collectedData.offenseDetails, null, 2)}

Chargesheet and Case Stage:
${JSON.stringify(collectedData.chargesheetCaseStage, null, 2)}

Evidence Details:
${JSON.stringify(collectedData.evidenceDetails, null, 2)}

Bail and Custody:
${JSON.stringify(collectedData.bailAndCustody, null, 2)}

Grounds for Quashment:
${JSON.stringify(collectedData.groundsForQuashment, null, 2)}

**Template Draft**:
${modelDraft}
console.log("Collected Data for Prompt:", collectedData);

`;

     // Send the prompt to the backend
    const response = await fetch("https://genlawyers.com/api/generate-petition", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }),
      });
  
      const data = await response.json();
      if (response.ok) {
        setGeneratedPetition(data.petition);
      } else {
        console.error("Error from backend:", data.error);
      }
    } catch (error) {
      console.error("Error generating petition:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      paddingBottom: "100px", // Ensure footer space
    },
    generateButton: {
      padding: "15px 20px",
      backgroundColor: "linear-gradient(to right, #4caf50, #81c784)",
      border: "none",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "16px",
      borderRadius: "8px",
      cursor: "pointer",
      textAlign: "center",
      alignSelf: "center",
      marginTop: "20px",
      width: "200px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
    footer: {
      background: "linear-gradient(to right, #2c3e50, #34495e)",
      color: "#ecf0f1",
      padding: "20px",
      marginTop: "30px",
      textAlign: "center",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      boxShadow: "0px -2px 8px rgba(0, 0, 0, 0.1)",
    },
    footerText: {
      marginBottom: "10px",
      fontSize: "14px",
      color: "white"
    },
    footerCopyright: {
      fontSize: "12px",
      opacity: 0.8,
      color: "white"

    },
  };

  return (
    <div style={styles.container}>
      <h2>482 CrPC Quashment Petition</h2>

      {/* Sections */}
      <PersonalDetails
        label="Petitioner"
        data={formData.personalDetails}
        setData={(data) => updateSectionData("personalDetails", data)}
      />

        <FIRDetails
        data={formData.firDetails}
        setData={(data) => updateSectionData("firDetails", data)}
        />


      <OffenseDetails
        data={formData.offenseDetails}
        setData={(data) => updateSectionData("offenseDetails", data)}
      />

      <ChargesheetCaseStage
        data={formData.ChargesheetCaseStage}
        setData={(data) => updateSectionData("ChargesheetCaseStage", data)}
      />

      <EvidenceDetails
        data={formData.EvidenceDetails}
        setData={(data) => updateSectionData("EvidenceDetails", data)}
      />

      <BailAndCustody
        data={formData.BailAndCustody}
        setData={(data) => updateSectionData("BailAndCustody", data)}
      />

      <GroundsForQuashment
        data={formData.GroundsForQuashment}
        setData={(data) => updateSectionData("GroundsForQuashment", data)}
      />

      {/* Generate Button */}
      <button
        className="generate-button"
        onClick={handleGenerateClick}
        disabled={isGenerating}
      >
        {isGenerating ? "Generating..." : "Generate Petition"}
      </button>

      {/* Show Generated Petition */}
      {generatedPetition && (
        <div className="petition-output">
          <h3>Generated Petition:</h3>
          <textarea
            readOnly
            value={generatedPetition}
            style={{ width: "100%", height: "300px", padding: "10px" }}
          />
        </div>
      )}


      {/* Footer */}
      <div style={styles.footer}>
        <p style={styles.footerText}>
          Disclaimer: The drafts are for information purposes only, and Advocates are required to review them thoroughly.
        </p>
        <p style={styles.footerCopyright}>
          Copyrights (c) 2024 GenLawyers
        </p>
      </div>
    </div>
  );
}
