import React, { useState } from "react";
import CrPC482Questionnaire from "./petitions/CrPC482Questionnaire";
import BailQuestionnaire from "./petitions/BailQuestionnaire";
import "./DocDraft.css";

const legalDocuments = [
  "482 CrPC Quashment Petition",
  // "Bail Application",
  // "Civil Suit",
  // "Contract Drafting",
];


export default function LegalDraftPage() {
  const [selectedDocument, setSelectedDocument] = useState(legalDocuments[0]); // Manage selected document

  const [formData, setFormData] = useState({
    personalDetails: {},
    FIRDetails: {},
    offenseDetails: {},
    ChargesheetCaseStage: {},
    EvidenceDetails: {},
    BailAndCustody: {},
    GroundsForQuashment: {},
  });
  

  // Update section data
  const updateSectionData = (section, data) => {
    setFormData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };

  // Dynamic Questionnaire Rendering
  const renderQuestionnaire = () => {
    switch (selectedDocument) {
      case "482 CrPC Quashment Petition":
        return (
          <CrPC482Questionnaire
            formData={formData}
            setFormData={setFormData}
            updateSectionData={updateSectionData}
          />
        );
      case "Bail Application":
        return (
          <BailQuestionnaire
            formData={formData}
            setFormData={setFormData}
            updateSectionData={updateSectionData}
          />
        );
      default:
        return (
          <div className="placeholder">
            Select a document to view the questionnaire.
          </div>
        );
    }
  };

  return (
    <div className="containerDoc">
      {/* Sidebar */}
      <div className="sidebar">
        {legalDocuments.map((doc, index) => (
          <button
            key={index}
            className={`menu-item ${
              selectedDocument === doc ? "active-menu-item" : ""
            }`}
            onClick={() => setSelectedDocument(doc)}
          >
            {doc}
          </button>
        ))}
      </div>

      {/* Main Content */}
      <div className="content">{renderQuestionnaire()}</div>
    </div>
  );
}
