import React, { useState, useEffect } from "react";

export default function ChargesheetCaseStage({ data, setData }) {
    const [details, setDetails] = useState(
      data || {
        chargesheetFiled: "no",
        challanNumber: "",
        courtName: "",
        caseStage: "",
        chargesFramed: "no",
      }
    );

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(details)) {
          setData(details);
        }
      }, [details, setData, data]);
      

  const handleChange = (field, value) => {
    setDetails({ ...details, [field]: value });
  };

  const styles = {
    container: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      marginBottom: "20px",
      width: "100%",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "600",
      borderBottom: "1px solid #ddd",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    formRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#555",
      marginRight: "10px",
      flex: "1",
    },
    radioGroup: {
      display: "flex",
      gap: "10px",
      flex: "2",
    },
    input: {
      padding: "10px",
      borderRadius: "6px",
      border: "1px solid #ccc",
      fontSize: "14px",
      flex: "2",
    },
    select: {
      padding: "10px",
      borderRadius: "6px",
      border: "1px solid #ccc",
      fontSize: "14px",
      backgroundColor: "#fff",
      flex: "2",
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Chargesheet and Case Stage</h3>

      {/* Chargesheet Filed */}
      <div style={styles.formRow}>
        <label style={styles.label}>Has the chargesheet been filed?</label>
        <div style={styles.radioGroup}>
          <label>
            <input
              type="radio"
              value="yes"
              checked={details.chargesheetFiled === "yes"}
              onChange={() => handleChange("chargesheetFiled", "yes")}
            />{" "}
            Yes
          </label>
          <label>
            <input
              type="radio"
              value="no"
              checked={details.chargesheetFiled === "no"}
              onChange={() => handleChange("chargesheetFiled", "no")}
            />{" "}
            No
          </label>
        </div>
      </div>

      {/* Conditional Fields if Chargesheet is Filed */}
      {details.chargesheetFiled === "yes" && (
        <>
          <div style={styles.formRow}>
            <label style={styles.label}>Challan/Case Number:</label>
            <input
              type="text"
              value={details.challanNumber}
              onChange={(e) => handleChange("challanNumber", e.target.value)}
              placeholder="Enter Challan/Case Number"
              style={styles.input}
            />
          </div>
          <div style={styles.formRow}>
            <label style={styles.label}>Court Name:</label>
            <input
              type="text"
              value={details.courtName}
              onChange={(e) => handleChange("courtName", e.target.value)}
              placeholder="Enter Court Name"
              style={styles.input}
            />
          </div>

          {/* Case Stage Dropdown */}
          <div style={styles.formRow}>
            <label style={styles.label}>Case Stage:</label>
            <select
              value={details.caseStage}
              onChange={(e) => handleChange("caseStage", e.target.value)}
              style={styles.select}
            >
              <option value="">Select Case Stage</option>
              <option value="Investigation Pending">Investigation Pending</option>
              <option value="Charges Framed">Charges Framed</option>
              <option value="Trial Ongoing">Trial Ongoing</option>
              <option value="Post-Trial">Post-Trial</option>
            </select>
          </div>
        </>
      )}

      {/* Charges Framed */}
      <div style={styles.formRow}>
        <label style={styles.label}>Are the charges framed?</label>
        <div style={styles.radioGroup}>
          <label>
            <input
              type="radio"
              value="yes"
              checked={details.chargesFramed === "yes"}
              onChange={() => handleChange("chargesFramed", "yes")}
            />{" "}
            Yes
          </label>
          <label>
            <input
              type="radio"
              value="no"
              checked={details.chargesFramed === "no"}
              onChange={() => handleChange("chargesFramed", "no")}
            />{" "}
            No
          </label>
        </div>
      </div>
    </div>
  );
}
