import React, { useState, useEffect } from "react";

export default function FIRDetails({ data, setData }) {
  // Initialize details with passed data or default values
  const [details, setDetails] = useState(
    data || {
      firNumber: "",
      firDate: "",
      policeStation: "",
      state: "",
      howRegistered: "police_suo_motu",
      irregularities: "",
      related: "no",
      relationshipDetails: "",
      rivalry: "no",
      rivalryDetails: "",
      privateComplainant: {
        fullName: "",
        guardian: "",
        address: "",
        pinCode: "",
      },
    }
  );

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(details)) {
      setData(details);
    }
  }, [details, setData, data]);
  

  const handleChange = (field, value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleNestedChange = (parent, field, value) => {
    setDetails((prev) => ({
      ...prev,
      [parent]: { ...prev[parent], [field]: value },
    }));
  };

  const styles = {
    container: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "600",
      borderBottom: "1px solid #ddd",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    formGroup: {
      flex: "0 0 48%",
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    fullWidth: {
      flex: "0 0 100%",
    },
    label: {
      fontWeight: "500",
      fontSize: "14px",
    },
    input: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "6px",
    },
    textarea: {
      padding: "10px",
      borderRadius: "6px",
      border: "1px solid #ccc",
      resize: "vertical",
      fontSize: "14px",
    },
    select: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "6px",
    },
    radioGroup: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>FIR Details</h3>

      {/* FIR Number and Date */}
      <div style={styles.formRow}>
        <div style={styles.formGroup}>
          <label style={styles.label}>FIR Number</label>
          <input
            type="text"
            value={details.firNumber}
            onChange={(e) => handleChange("firNumber", e.target.value)}
            placeholder="Enter FIR Number"
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Date of FIR</label>
          <input
            type="date"
            value={details.firDate}
            onChange={(e) => handleChange("firDate", e.target.value)}
            style={styles.input}
          />
        </div>
      </div>

      {/* Police Station and State */}
      <div style={styles.formRow}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Police Station</label>
          <input
            type="text"
            value={details.policeStation}
            onChange={(e) => handleChange("policeStation", e.target.value)}
            placeholder="Enter Police Station Name"
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>State</label>
          <input
            type="text"
            value={details.state}
            onChange={(e) => handleChange("state", e.target.value)}
            placeholder="Enter State"
            style={styles.input}
          />
        </div>
      </div>

      {/* How FIR was Registered */}
      <div style={{ marginBottom: "20px" }}>
        <label style={styles.label}>How was the FIR registered?</label>
        <select
          value={details.howRegistered}
          onChange={(e) => handleChange("howRegistered", e.target.value)}
          style={styles.select}
        >
          <option value="police_suo_motu">By Police (Suo Motu)</option>
          <option value="private_complaint">On a Private Complaint</option>
          <option value="magistrate_order">
            By an Order of the Magistrate under Section 156(3) CrPC
          </option>
          <option value="informant_witness">Through an Informant/Witness</option>
        </select>
      </div>

      {/* Private Complaint Additional Fields */}
      {details.howRegistered === "private_complaint" && (
        <>
          <div style={styles.formRow}>
            <div style={styles.formGroup}>
              <label style={styles.label}>Full Name</label>
              <input
                type="text"
                value={details.privateComplainant.fullName}
                onChange={(e) =>
                  handleNestedChange("privateComplainant", "fullName", e.target.value)
                }
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>S/o, D/o, W/o</label>
              <input
                type="text"
                value={details.privateComplainant.guardian}
                onChange={(e) =>
                  handleNestedChange("privateComplainant", "guardian", e.target.value)
                }
                style={styles.input}
              />
            </div>
          </div>
          <div style={styles.formRow}>
            <div style={styles.formGroup}>
              <label style={styles.label}>Address</label>
              <input
                type="text"
                value={details.privateComplainant.address}
                onChange={(e) =>
                  handleNestedChange("privateComplainant", "address", e.target.value)
                }
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Pin Code</label>
              <input
                type="text"
                value={details.privateComplainant.pinCode}
                onChange={(e) =>
                  handleNestedChange("privateComplainant", "pinCode", e.target.value)
                }
                style={styles.input}
              />
            </div>
          </div>

          {/* Relationship Between Accused and Complainant */}
          <div style={styles.formGroup}>
            <label style={styles.label}>Are the accused and complainant related?</label>
            <div style={styles.radioGroup}>
              <label>
                <input
                  type="radio"
                  value="yes"
                  checked={details.related === "yes"}
                  onChange={() => handleChange("related", "yes")}
                />{" "}
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  value="no"
                  checked={details.related === "no"}
                  onChange={() => handleChange("related", "no")}
                />{" "}
                No
              </label>
            </div>
            {details.related === "yes" && (
              <textarea
                value={details.relationshipDetails}
                onChange={(e) => handleChange("relationshipDetails", e.target.value)}
                placeholder="Describe the relationship"
                rows="3"
                style={styles.textarea}
              />
            )}
          </div>

          {/* Past Rivalry */}
          <div style={styles.formGroup}>
            <label style={styles.label}>
              Is there any past rivalry between the accused and the complainant?
            </label>
            <div style={styles.radioGroup}>
              <label>
                <input
                  type="radio"
                  value="yes"
                  checked={details.rivalry === "yes"}
                  onChange={() => handleChange("rivalry", "yes")}
                />{" "}
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  value="no"
                  checked={details.rivalry === "no"}
                  onChange={() => handleChange("rivalry", "no")}
                />{" "}
                No
              </label>
            </div>
            {details.rivalry === "yes" && (
              <textarea
                value={details.rivalryDetails}
                onChange={(e) => handleChange("rivalryDetails", e.target.value)}
                placeholder="Provide details of the rivalry"
                rows="3"
                style={styles.textarea}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
