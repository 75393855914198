import React, { useState, useEffect } from "react";

export default function EvidenceDetails({ setData }) {
  const [details, setDetails] = useState({
    evidenceTypes: [],
    otherEvidence: "",
    incriminatingMaterialRecovered: "no",
    recoveredMaterial: "",
    confessionByAccused: "no",
    confessionMadeTo: "",
  });

  const evidenceOptions = [
    "Confession",
    "Witness Testimony",
    "Recovery of Weapon",
    "Recovery of Stolen Property",
    "Recovery of Narcotics",
    "Documentary Evidence",
    "Forensic Evidence",
    "Other",
  ];

  const handleCheckboxChange = (option) => {
    setDetails((prev) => {
      const updatedEvidence = prev.evidenceTypes.includes(option)
        ? prev.evidenceTypes.filter((item) => item !== option)
        : [...prev.evidenceTypes, option];
      return { ...prev, evidenceTypes: updatedEvidence };
    });
  };

  const handleChange = (field, value) => {
    setDetails((prev) => ({ ...prev, [field]: value }));
  };

  // UseEffect to update parent data only when details change
  useEffect(() => {
    if (setData) {
      setData(details);
    }
  }, [details]); // Trigger only when `details` changes

  const styles = {
    container: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      marginBottom: "20px",
      width: "100%",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "600",
      borderBottom: "1px solid #ddd",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    formRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "500",
      marginRight: "10px",
      color: "#555",
    },
    input: {
      padding: "10px",
      borderRadius: "6px",
      border: "1px solid #ccc",
      fontSize: "14px",
      width: "100%",
    },
    checkboxGroup: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },
    radioGroup: {
      display: "flex",
      gap: "15px",
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Evidence Details</h3>

      {/* Type of Evidence */}
      <div style={styles.formGroup}>
        <label style={styles.label}>What type of evidence supports the FIR?</label>
        <div style={styles.checkboxGroup}>
          {evidenceOptions.map((option) => (
            <label key={option} style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={details.evidenceTypes.includes(option)}
                onChange={() => handleCheckboxChange(option)}
                style={{ marginRight: "5px" }}
              />
              {option}
            </label>
          ))}
        </div>
        {details.evidenceTypes.includes("Other") && (
          <input
            type="text"
            value={details.otherEvidence}
            onChange={(e) => handleChange("otherEvidence", e.target.value)}
            placeholder="Specify other evidence"
            style={styles.input}
          />
        )}
      </div>

      {/* Incriminating Material */}
      <div style={styles.formRow}>
        <label style={styles.label}>Has the prosecution recovered any incriminating material?</label>
        <div style={styles.radioGroup}>
          <label>
            <input
              type="radio"
              value="yes"
              checked={details.incriminatingMaterialRecovered === "yes"}
              onChange={() => handleChange("incriminatingMaterialRecovered", "yes")}
            />{" "}
            Yes
          </label>
          <label>
            <input
              type="radio"
              value="no"
              checked={details.incriminatingMaterialRecovered === "no"}
              onChange={() => handleChange("incriminatingMaterialRecovered", "no")}
            />{" "}
            No
          </label>
        </div>
      </div>
      {details.incriminatingMaterialRecovered === "yes" && (
        <div style={styles.formGroup}>
          <label style={styles.label}>Specify the recovered material:</label>
          <input
            type="text"
            value={details.recoveredMaterial}
            onChange={(e) => handleChange("recoveredMaterial", e.target.value)}
            placeholder="Enter details of the recovered material"
            style={styles.input}
          />
        </div>
      )}

      {/* Confession by Accused */}
      <div style={styles.formRow}>
        <label style={styles.label}>Has there been a confession by the accused?</label>
        <div style={styles.radioGroup}>
          <label>
            <input
              type="radio"
              value="yes"
              checked={details.confessionByAccused === "yes"}
              onChange={() => handleChange("confessionByAccused", "yes")}
            />{" "}
            Yes
          </label>
          <label>
            <input
              type="radio"
              value="no"
              checked={details.confessionByAccused === "no"}
              onChange={() => handleChange("confessionByAccused", "no")}
            />{" "}
            No
          </label>
        </div>
      </div>
      {details.confessionByAccused === "yes" && (
        <div style={styles.formGroup}>
          <label style={styles.label}>Was the confession made to:</label>
          <div style={styles.radioGroup}>
            <label>
              <input
                type="radio"
                value="Police"
                checked={details.confessionMadeTo === "Police"}
                onChange={() => handleChange("confessionMadeTo", "Police")}
              />{" "}
              Police
            </label>
            <label>
              <input
                type="radio"
                value="Judicial Magistrate"
                checked={details.confessionMadeTo === "Judicial Magistrate"}
                onChange={() => handleChange("confessionMadeTo", "Judicial Magistrate")}
              />{" "}
              Judicial Magistrate
            </label>
          </div>
        </div>
      )}
    </div>
  );
}
