import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Firebase configuration
import { doc, getDoc } from 'firebase/firestore'; // Firestore functions
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

const LegalResearchAssistant = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : null; // Get the user ID from Firebase Auth
  const [userName, setUserName] = useState('User'); // Default to "User" if no name is found
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [caseLogs, setCaseLogs] = useState([]); // For storing real-time logs

  // Fetch user name from Firestore
  useEffect(() => {
    const fetchUserName = async () => {
      if (!userId) return;

      try {
        const docRef = doc(db, 'users', userId); // Reference to the user's document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserName(docSnap.data().advocateName || 'User'); // Update user name
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserName();
  }, [userId]);

  // Refine Query API Call
  const handleRefineQuery = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://genlawyers.com/legal-research', {
        userId,
        query,
        action: 'refine',
      });
      setSuggestions(response.data.suggestions || []);
    } catch (err) {
      setError(err.response?.data?.error || 'Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  // Process Query API Call
  const handleProcessQuery = async () => {
  setIsLoading(true);
  setError(null);
  setCaseLogs([]); // Clear previous logs
  setReport(null); // Clear previous report

  try {
    const response = await axios.post('https://genlawyers.com/legal-research', {
      userId,
      query,
      action: 'process',
      selected_suggestion: selectedSuggestion,
    });

    // Correctly access the report key
    if (response.data.report) {
      setReport(response.data.report); // Set the generated research report
    } else if (response.data.error) {
      setError(response.data.error); // Handle errors returned by the API
    }
  } catch (err) {
    setError(err.response?.data?.error || 'Something went wrong.');
  } finally {
    setIsLoading(false);
  }
};

  

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'scroll',
        background: 'linear-gradient(to bottom, #f7f7f7, #ffffff)', // Light metallic white theme
        color: '#333', // Dark text color for readability
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      {/* Display User Name */}
      <h2 style={{ marginBottom: '10px', color: '#007BFF', fontSize: '24px' }}>
        Welcome, {userName}!
      </h2>
      <h1 style={{ marginBottom: '30px', fontSize: '36px', letterSpacing: '1px', color: '#333' }}>
        Legal Research Assistant
      </h1>

      {/* Input Section */}
      <div style={{ width: '100%', maxWidth: '600px', marginBottom: '20px' }}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query..."
          style={{
            width: '100%',
            padding: '15px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            color: '#333',
            fontSize: '16px',
            marginBottom: '15px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        />
        <button
          onClick={handleRefineQuery}
          disabled={isLoading}
          style={{
            width: '100%',
            padding: '15px',
            borderRadius: '5px',
            background: isLoading
              ? '#cccccc'
              : 'linear-gradient(90deg, #007BFF, #0056b3)', // Gradient button with modern style
            color: '#fff',
            fontSize: '18px',
            cursor: isLoading ? 'not-allowed' : 'pointer',
            transition: 'background 0.3s ease',
          }}
        >
          {isLoading ? 'Processing...' : 'Refine Query'}
        </button>
      </div>

      {/* Suggestions Section */}
      {suggestions.length > 0 && (
        <div
          style={{
            width: '100%',
            maxWidth: '600px',
            background: '#fff',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            marginBottom: '20px',
          }}
        >
          <h3 style={{ marginBottom: '10px', color: '#007BFF' }}>Suggestions</h3>
          {suggestions.map((suggestion, index) => (
            <div key={index} style={{ marginBottom: '10px', color: '#333' }}>
              <label>
                <input
                  type="radio"
                  name="suggestion"
                  value={suggestion}
                  onChange={() => setSelectedSuggestion(suggestion)}
                  style={{ marginRight: '10px' }}
                />
                {suggestion}
              </label>
            </div>
          ))}
          <button
            onClick={handleProcessQuery}
            disabled={isLoading || !selectedSuggestion}
            style={{
              width: '100%',
              padding: '15px',
              borderRadius: '5px',
              background: isLoading
                ? '#cccccc'
                : 'linear-gradient(90deg, #007BFF, #0056b3)',
              color: '#fff',
              fontSize: '18px',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              transition: 'background 0.3s ease',
            }}
          >
            {isLoading ? 'Processing...' : 'Process Selected Query'}
          </button>
        </div>
      )}

{caseLogs.length > 0 && (
  <div
    style={{
      width: '100%',
      maxWidth: '800px',
      padding: '20px',
      borderRadius: '10px',
      background: '#ffffff',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#333',
      marginBottom: '20px',
      overflowY: 'scroll',
      height: '200px', // Fixed height with scrolling
    }}
  >
    <h3 style={{ color: '#007BFF' }}>Case Processing Logs:</h3>
    <ul style={{ margin: '0', padding: '0', listStyleType: 'none', lineHeight: '1.6' }}>
      {caseLogs.map((log, index) => (
        <li key={index} style={{ color: log.includes('Relevant case') ? '#28a745' : '#555' }}>
          {log}
        </li>
      ))}
    </ul>
  </div>
)}
{/* Case Logs Section */}
{caseLogs.length > 0 && (
  <div
    style={{
      width: '100%',
      maxWidth: '800px',
      padding: '20px',
      borderRadius: '10px',
      background: '#ffffff',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      color: '#333',
      marginBottom: '20px',
      overflowY: 'scroll',
      height: '200px', // Set a fixed height for scrolling
    }}
  >
    <h3 style={{ color: '#007BFF' }}>Case Processing Logs:</h3>
    <ul style={{ margin: '0', padding: '0', listStyleType: 'none', lineHeight: '1.6' }}>
      {caseLogs.map((log, index) => (
        <li key={index} style={{ color: log.includes('Relevant case') ? '#28a745' : '#555' }}>
          {log}
        </li>
      ))}
    </ul>
  </div>
)}


      {/* Research Report Section */}
      {report && (
        <div
          style={{
            width: '100%',
            maxWidth: '800px',
            padding: '20px',
            borderRadius: '10px',
            background: '#ffffff',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            color: '#333',
            marginBottom: '30px',
          }}
        >
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#007BFF' }} {...props} />,
              h2: ({ node, ...props }) => <h2 style={{ fontSize: '20px', fontWeight: 'bold', color: '#007BFF' }} {...props} />,
              h3: ({ node, ...props }) => <h3 style={{ fontSize: '18px', fontWeight: 'bold', color: '#333' }} {...props} />,
              p: ({ node, ...props }) => <p style={{ marginBottom: '10px', color: '#555' }} {...props} />,
            }}
          >
            {report}
          </ReactMarkdown>
        </div>
      )}

      {/* Error Section */}
      {error && (
        <p style={{ color: '#f44336', marginTop: '20px', fontSize: '16px' }}>
          <strong>Error:</strong> {error}
        </p>
      )}
    </div>
  );
};

export default LegalResearchAssistant;
