import React, { useState, useEffect } from "react";

export default function BailAndCustody({ data, setData }) {
    const [details, setDetails] = useState(
      data || {
        accusedOnBail: "no",
        bailDate: "",
        bailComplied: "no",
        attendingHearings: "no",
        custodyDuration: "",
        custodyPlace: "",
      }
    );

    const handleChange = (field, value) => {
        setDetails((prevDetails) => ({
          ...prevDetails,
          [field]: value,
        }));
      };

      useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(details)) {
          setData(details);
        }
      }, [details, setData, data]);
      

  const styles = {
    container: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      marginBottom: "20px",
      width: "100%",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "600",
      borderBottom: "1px solid #ddd",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    formRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#555",
      marginRight: "10px",
    },
    input: {
      padding: "8px",
      borderRadius: "6px",
      border: "1px solid #ccc",
      fontSize: "14px",
      width: "100%",
    },
    radioGroup: {
      display: "flex",
      gap: "10px",
    },
    radioLabel: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Bail and Custody</h3>

      {/* Is the accused on bail? */}
      <div style={styles.formRow}>
        <label style={styles.label}>Is the accused on bail?</label>
        <div style={styles.radioGroup}>
          <label style={styles.radioLabel}>
            <input
              type="radio"
              value="yes"
              checked={details.accusedOnBail === "yes"}
              onChange={() => handleChange("accusedOnBail", "yes")}
            />{" "}
            Yes
          </label>
          <label style={styles.radioLabel}>
            <input
              type="radio"
              value="no"
              checked={details.accusedOnBail === "no"}
              onChange={() => handleChange("accusedOnBail", "no")}
            />{" "}
            No
          </label>
        </div>
      </div>

      {/* Conditional Fields if Accused is on Bail */}
      {details.accusedOnBail === "yes" && (
        <>
          <div style={styles.formRow}>
            <label style={styles.label}>Date of Bail</label>
            <input
              type="date"
              value={details.bailDate}
              onChange={(e) => handleChange("bailDate", e.target.value)}
              style={styles.input}
            />
          </div>

          {/* Bail Conditions */}
          <div style={styles.formRow}>
            <label style={styles.label}>
              Has the accused complied with all bail conditions?
            </label>
            <div style={styles.radioGroup}>
              <label style={styles.radioLabel}>
                <input
                  type="radio"
                  value="yes"
                  checked={details.bailComplied === "yes"}
                  onChange={() => handleChange("bailComplied", "yes")}
                />{" "}
                Yes
              </label>
              <label style={styles.radioLabel}>
                <input
                  type="radio"
                  value="no"
                  checked={details.bailComplied === "no"}
                  onChange={() => handleChange("bailComplied", "no")}
                />{" "}
                No
              </label>
            </div>
          </div>

          {/* Attending Court Hearings */}
          <div style={styles.formRow}>
            <label style={styles.label}>
              Has the accused been attending court hearings?
            </label>
            <div style={styles.radioGroup}>
              <label style={styles.radioLabel}>
                <input
                  type="radio"
                  value="yes"
                  checked={details.attendingHearings === "yes"}
                  onChange={() => handleChange("attendingHearings", "yes")}
                />{" "}
                Yes
              </label>
              <label style={styles.radioLabel}>
                <input
                  type="radio"
                  value="no"
                  checked={details.attendingHearings === "no"}
                  onChange={() => handleChange("attendingHearings", "no")}
                />{" "}
                No
              </label>
            </div>
          </div>
        </>
      )}

      {/* Conditional Fields if Accused is NOT on Bail */}
      {details.accusedOnBail === "no" && (
        <>
          <div style={styles.formRow}>
            <label style={styles.label}>Duration in Judicial Custody</label>
            <input
              type="text"
              value={details.custodyDuration}
              onChange={(e) => handleChange("custodyDuration", e.target.value)}
              placeholder="Enter duration (e.g., Days/Months)"
              style={styles.input}
            />
          </div>
          <div style={styles.formRow}>
            <label style={styles.label}>Place of Custody (e.g., Jail Name)</label>
            <input
              type="text"
              value={details.custodyPlace}
              onChange={(e) => handleChange("custodyPlace", e.target.value)}
              placeholder="Enter place of custody"
              style={styles.input}
            />
          </div>
        </>
      )}
    </div>
  );
}
