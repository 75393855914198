import React, { useState, useEffect } from "react";

export default function GroundsForQuashment({ data, setData }) {
  const [details, setDetails] = useState(
    data || {
    selectedGrounds: [],
    otherGround: "",
    settlementAgreement: null,
    }
  );

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(details)) {
      setData(details);
    }
  }, [details, setData, data]);
  


  const groundsList = [
    "No prima facie case is made out.",
    "Allegations do not disclose a cognizable offense.",
    "The FIR/complaint is registered with malafide intent or ulterior motive.",
    "The case is absurd, improbable, or inherently impossible.",
    "The complaint is barred under a statutory provision.",
    "Procedural irregularities, including lack of Magistrate's approval for non-cognizable offenses.",
    "Continuation of proceedings amounts to abuse of the court process.",
    "Settlement between parties (with evidence of settlement).",
    "Lack of supporting evidence to sustain the case.",
    "Offense is trivial, technical, or not serious enough for criminal prosecution.",
    "Other (to cover additional reasons as per the specific case).",
  ];

  const handleCheckboxChange = (ground) => {
    setDetails((prev) => {
      const updatedGrounds = Array.isArray(prev.selectedGrounds) && prev.selectedGrounds.includes(ground)
        ? prev.selectedGrounds.filter((item) => item !== ground)
        : [...(prev.selectedGrounds || []), ground];
      return { ...prev, selectedGrounds: updatedGrounds };
    });
  };

  const handleChange = (field, value) => {
    setDetails({ ...details, [field]: value });
  };

  const styles = {
    container: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      marginBottom: "20px",
      width: "100%",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "600",
      borderBottom: "1px solid #ddd",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    checkboxGroup: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      marginBottom: "20px",
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#555",
      marginBottom: "5px",
    },
    input: {
      padding: "10px",
      borderRadius: "6px",
      border: "1px solid #ccc",
      fontSize: "14px",
      width: "100%",
    },
    fileInput: {
      padding: "5px",
      fontSize: "14px",
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Grounds for Quashment</h3>

      {/* Checkbox Group for Grounds */}
      <div style={styles.checkboxGroup}>
        {groundsList.map((ground) => (
          <label key={ground} style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={Array.isArray(details.selectedGrounds) && details.selectedGrounds.includes(ground)}
              onChange={() => handleCheckboxChange(ground)}
              style={{ marginRight: "8px" }}
            />
            {ground}
          </label>
        ))}
      </div>

      {/* Settlement Agreement Upload */}
      {Array.isArray(details.selectedGrounds) &&
        details.selectedGrounds.includes("Settlement between parties (with evidence of settlement).") && (
          <div style={styles.formGroup}>
            <label style={styles.label}>Upload Settlement Agreement</label>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={(e) => handleChange("settlementAgreement", e.target.files[0])}
              style={styles.fileInput}
            />
          </div>
        )}

      {/* Other Ground Text Field */}
      {Array.isArray(details.selectedGrounds) &&
        details.selectedGrounds.includes("Other (to cover additional reasons as per the specific case).") && (
          <div style={styles.formGroup}>
            <label style={styles.label}>Specify Other Grounds</label>
            <input
              type="text"
              value={details.otherGround}
              onChange={(e) => handleChange("otherGround", e.target.value)}
              placeholder="Describe other grounds"
              style={styles.input}
            />
          </div>
        )}
    </div>
  );
}