import React from "react";
import PersonalDetails from "../sections/PersonalDetails";

export default function BailQuestionnaire({ formData, setFormData }) {
  const updateSectionData = (section, data) => {
    setFormData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };

  return (
    <div className="questionnaire">
      <h2>Bail Application</h2>
      <PersonalDetails
        label="Applicant"
        data={formData.personalDetails}
        setData={(data) => updateSectionData("personalDetails", data)}
      />
      {/* Add more bail-specific sections */}
    </div>
  );
}
