import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LawyerRegistrationForm from './components/LawyerRegistrationForm';
import StateSelection from './components/StateSelection';
import DistrictSelection from './components/DistrictSelection';
import ComplexSelection from './components/ComplexSelection';
import HighCourtSelection from './components/HighCourtSelection';
import CATSelection from './components/CATSelection';
import ReviewSelection from './components/ReviewSelection';
import Dashboard from './components/Dashboard';
import AIChat from './components/AIChat';
import Profile from './components/Profile';
import Calendar from './components/Calendar';
import Team from './components/Team';
import DocDraft from './components/DocDraft';
import TopBar from './components/TopBar';
import Home from './components/Home'; // Import the Home component
import Login from './components/Login'; // Import the Login component
import UpcomingTodos from './components/UpcomingToDos'; 
import AddCasePage from './components/AddCasePage';
import './App.css';
import { UserProvider } from './UserContext'; // Import UserProvider
import { useNavigate } from 'react-router-dom';
import { db, auth } from './firebaseConfig'; // Make sure to import auth
import CaseDetail from './components/CaseDetail';
import LegalResearchPage from './components/caseLaw';


function App() {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const navigate = useNavigate(); // Correctly define navigate
  const location = useLocation();

  useEffect(() => {
    setUserId(localStorage.getItem('userId') || null);
  }, []);

  // Effect to check if a user is logged in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);  // Set the userId when the user is logged in
        localStorage.setItem('userId', user.uid);
      } else {
        setUserId(null);
        localStorage.removeItem('userId');
      }
    });
    return () => unsubscribe();
  }, []);

  const [userData, setUserData] = useState({
    advocateName: '',
    email: '',
    mobile: '',
    licenseNumber: '',
    selectedStates: [],
    selectedDistricts: {},
    selectedComplexes: {},
    selectedHCStates: {},
    selectedCATBench: ''
  });

  
  // Specify the routes where TopBar should NOT be shown
  const noTopBarRoutes = [
    '/',
    '/login',
    '/register',
    '/state-selection',
    '/district-selection',
    '/complex-selection',
    '/highcourt-selection',
    '/cat-selection',
    '/review-selection'
  ];

  const shouldShowTopBar = !noTopBarRoutes.includes(location.pathname);

  return (
    <div className="App">
      {shouldShowTopBar && <TopBar userId={userId} />}
      <Routes>
        {/* Home page as the main entry */}
        <Route path="/" element={<Home />} />
        
        {/* Registration and Login */}
        <Route path="/register" element={<LawyerRegistrationForm setUserData={setUserData} />} />
        <Route path="/login" element={<Login setUserId={setUserId} />} />
        
        {/* Selection Process */}
        <Route path="/state-selection" element={<StateSelection userData={userData} setUserData={setUserData} />} />
        <Route path="/district-selection" element={<DistrictSelection userId={userId} userData={userData} setUserData={setUserData} />} />
        <Route path="/complex-selection" element={<ComplexSelection userId={userId} userData={userData} setUserData={setUserData} />} />
        <Route path="/highcourt-selection" element={<HighCourtSelection userId={userId} userData={userData} setUserData={setUserData} />} />
        <Route path="/cat-selection" element={<CATSelection userData={userData} setUserData={setUserData} />} />
        <Route path="/review-selection" element={<ReviewSelection userData={userData} />} />

        {/* App features after login */}
        <Route path="/dashboard/:userId" element={<Dashboard />} />
        <Route path="/casedetail/:userId" element={<CaseDetail />} />
        <Route path="/ai-chat/:userId" element={<AIChat />} />
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/calendar/:userId" element={<Calendar />} />
        <Route path="/team/:userId" element={<Team />} />
        <Route path="/docdraft/:userId" element={<DocDraft />} />
        <Route path="/upcoming-todos/:userId" element={<UpcomingTodos />} />
        <Route path="/caseLaw/:userId" element={<LegalResearchPage />} />

        <Route path="/add-case" element={<AddCasePage />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  );
}

export default AppWrapper;
