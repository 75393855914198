import React, { useState, useEffect } from "react";

export default function PersonalDetails({ label, data, setData }) {
  const [details, setDetails] = useState(data || {
    firstName: "",
    lastName: "",
    age: "",
    fatherName: "",
    address: "",
    pinCode: "",
    maritalStatus: "single",
  });

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(details)) {
      setData(details);
    }
  }, [details, setData, data]);
  

  const handleChange = (field, value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };


  const styles = {
    container: {
      fontFamily: "'Roboto', sans-serif",
      color: "#333",
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      width: "100%", // Takes full width of the parent container
      marginBottom: "20px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "600",
      marginBottom: "20px",
      borderBottom: "1px solid #ddd",
      paddingBottom: "10px",
    },
    formRow: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    formGroup: {
      flex: "0 0 48%", // Take 48% of the width for two-column layout
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    fullWidth: {
      flex: "0 0 100%", // Full-width for single-column rows
    },
    label: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#555",
    },
    input: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "6px",
      transition: "border-color 0.3s",
    },
    textarea: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "6px",
      resize: "vertical",
    },
    select: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "6px",
      backgroundColor: "#fff",
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>{label} Personal Details</h3>

      {/* Name Fields */}
      <div style={styles.formRow}>
        <div style={styles.formGroup}>
          <label style={styles.label}>First Name</label>
          <input
            type="text"
            value={details.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            placeholder="Enter first name"
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Last Name</label>
          <input
            type="text"
            value={details.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            placeholder="Enter last name"
            style={styles.input}
          />
        </div>
      </div>

      {/* Age and Marital Status */}
      <div style={styles.formRow}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Age</label>
          <input
            type="number"
            value={details.age}
            onChange={(e) => handleChange("age", e.target.value)}
            placeholder="Enter age"
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Marital Status</label>
          <select
            value={details.maritalStatus}
            onChange={(e) => handleChange("maritalStatus", e.target.value)}
            style={styles.select}
          >
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="widowed">Widowed</option>
            <option value="divorced">Divorced</option>
          </select>
        </div>
      </div>

      {/* Father's/Husband's Name */}
      <div style={styles.formRow}>
        <div style={{ ...styles.formGroup, ...styles.fullWidth }}>
          <label style={styles.label}>Father's/Husband's Name</label>
          <input
            type="text"
            value={details.fatherName}
            onChange={(e) => handleChange("fatherName", e.target.value)}
            placeholder="Enter father's or husband's name"
            style={styles.input}
          />
        </div>
      </div>

      {/* Address */}
      <div style={styles.formRow}>
        <div style={{ ...styles.formGroup, ...styles.fullWidth }}>
          <label style={styles.label}>Address</label>
          <textarea
            value={details.address}
            onChange={(e) => handleChange("address", e.target.value)}
            placeholder="Enter full address"
            rows="3"
            style={styles.textarea}
          />
        </div>
      </div>

      {/* Pin Code */}
      <div style={styles.formRow}>
        <div style={{ ...styles.formGroup, ...styles.fullWidth }}>
          <label style={styles.label}>Pin Code</label>
          <input
            type="text"
            value={details.pinCode}
            onChange={(e) => handleChange("pinCode", e.target.value)}
            placeholder="Enter pin code"
            style={styles.input}
          />
        </div>
      </div>
    </div>
  );
}
