import React, { useState, useEffect } from "react";

export default function OffenseDetails( { data, setData } ) {
  const [details, setDetails] = useState(
    data || {
    selectedOffenses: [],
    otherOffense: "",
    act: "",
    cognizable: "cognizable",
    compoundable: "no",
  });

  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(details)) {
      setData(details);
    }
  }, [details, setData, data]);
  

  const offensesList = [
    {
      category: "Criminal Offenses",
      offenses: [
        "Theft",
        "Burglary",
        "Robbery",
        "Extortion",
        "Criminal Breach of Trust",
        "Cheating",
        "Forgery",
        "Fraud",
        "Misappropriation",
      ],
    },
    {
      category: "Economic Offenses",
      offenses: [
        "Money Laundering",
        "Tax Evasion",
        "Benami Transactions",
        "Banking Fraud",
        "Financial Scams",
      ],
    },
    {
      category: "Violence Offenses",
      offenses: [
        "Murder",
        "Attempt to Murder",
        "Assault",
        "Grievous Hurt",
        "Kidnapping",
        "Abduction",
        "Culpable Homicide",
      ],
    },
    {
      category: "Sexual Offenses",
      offenses: [
        "Rape",
        "Attempt to Rape",
        "Molestation",
        "Sexual Harassment",
        "POCSO Act Offenses",
      ],
    },
    {
      category: "Dowry-Related Offenses",
      offenses: [
        "Dowry Demand",
        "Dowry Harassment",
        "Domestic Violence",
        "Cruelty under IPC Section 498A",
      ],
    },
    {
      category: "Corruption Offenses",
      offenses: [
        "Bribery",
        "Misconduct by Public Servants",
        "Corruption in Office",
      ],
    },
    {
      category: "Cyber Crime Offenses",
      offenses: [
        "Identity Theft",
        "Data Breach",
        "Hacking",
        "Phishing",
        "Cyber Fraud",
        "Online Defamation",
      ],
    },
    {
      category: "Property Offenses",
      offenses: [
        "Arson",
        "Trespassing",
        "Mischief",
        "Criminal Damage to Property",
        "Encroachment",
      ],
    },
    {
      category: "Narcotics Offenses",
      offenses: [
        "Possession of Narcotics",
        "Sale/Transport of Drugs",
        "Manufacturing Narcotics",
      ],
    },
    {
      category: "Environmental Offenses",
      offenses: [
        "Pollution",
        "Illegal Mining",
        "Forest Act Violations",
        "Wildlife Protection Violations",
      ],
    },
    {
      category: "SC/ST Act Violations",
      offenses: [
        "Atrocities against SC/ST communities",
        "Caste-based Harassment",
      ],
    },
    {
      category: "Other Offenses",
      offenses: [
        "Contempt of Court",
        "Defamation",
        "Sedition",
        "Unlawful Assembly",
        "Rioting",
        "Disobedience of Court Orders",
        "Breach of Contract",
        "Negligence Leading to Injury",
        "Other",
      ],
    },
  ];
  

  const actsList = [
    "Indian Penal Code (IPC)",
    "Code of Criminal Procedure (CrPC)",
    "Bharatiya Nyaya Sanhita (Replaces IPC)",
    "Bharatiya Nagarik Suraksha Sanhita (Replaces CrPC)",
    "Bharatiya Sakshya Adhiniyam (BSA)",
    "Narcotic Drugs and Psychotropic Substances Act (NDPS)",
    "Prevention of Money Laundering Act (PMLA)",
    "Scheduled Castes and Scheduled Tribes (Prevention of Atrocities) Act",
    "Information Technology Act, 2000 (IT Act)",
    "Motor Vehicles Act",
    "Environment Protection Act",
    "Wildlife Protection Act",
    "Protection of Women from Domestic Violence Act",
    "Dowry Prohibition Act",
    "POCSO Act (Protection of Children from Sexual Offences)",
    "Consumer Protection Act",
    "Prevention of Corruption Act",
    "Factories Act",
    "Industrial Disputes Act",
    "Foreigners Act",
    "Arms Act",
    "Unlawful Activities Prevention Act (UAPA)",
    "Official Secrets Act",
    "Epidemic Diseases Act",
    "Juvenile Justice Act",
    "Essential Commodities Act",
    "Anti-Hijacking Act",
    "Anti-Terrorism Laws (TADA, POTA - Historical Reference)",
    "Passport Act",
    "The Wildlife (Protection) Act, 1972",
    "Air (Prevention and Control of Pollution) Act",
    "Water (Prevention and Control of Pollution) Act",
    "Cattle Trespass Act",
    "Explosives Act",
    "Explosives Substances Act",
    "Customs Act",
    "Armed Forces Special Powers Act (AFSPA)",
    "Contempt of Courts Act",
    "The Foreign Contribution (Regulation) Act, 2010 (FCRA)",
    "The Benami Transactions (Prohibition) Act",
    "Anti-Human Trafficking Laws",
    "Dowry Death and Cruelty Laws (IPC 304B, 498A)",
    "Indecent Representation of Women (Prohibition) Act",
    "Prohibition of Child Marriage Act",
    "Child Labour (Prohibition and Regulation) Act",
    "Other",
  ];
  
  const handleOffenseChange = (offense) => {
    setDetails((prev) => {
      const updatedOffenses = (prev.selectedOffenses || []).includes(offense)
        ? (prev.selectedOffenses || []).filter((item) => item !== offense)
        : [...(prev.selectedOffenses || []), offense];
      return { ...prev, selectedOffenses: updatedOffenses };
    });
  };
  

  const handleChange = (field, value) => {
    setDetails({ ...details, [field]: value });
  };

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const styles = {
    container: { padding: "20px", width: "100%", backgroundColor: "#fff", borderRadius: "8px" },
    heading: { fontSize: "20px", marginBottom: "15px", borderBottom: "1px solid #ddd", paddingBottom: "10px" },
    categoryHeading: {
      fontSize: "16px",
      fontWeight: "600",
      cursor: "pointer",
      backgroundColor: "#f0f0f0",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
    },
    checkboxGroup: { display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" },
    formRow: { marginBottom: "20px" },
    label: { display: "flex", alignItems: "center" },
    input: { padding: "8px", width: "100%", marginTop: "5px" },
    select: { padding: "8px", width: "100%", marginTop: "5px" },
  };
  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Offense Details</h3>

      {/* Offense Categories with Expandable/Collapsible Sections */}
      {offensesList.map((category, idx) => (
        <div key={idx}>
          <div
            style={styles.categoryHeading}
            onClick={() => toggleCategory(category.category)}
          >
            {category.category} {expandedCategories[category.category] ? "▲" : "▼"}
          </div>

          {expandedCategories[category.category] && (
            <div style={styles.checkboxGroup}>
              {category.offenses.map((offense) => (
                <label key={offense} style={styles.label}>
                  <input
                    type="checkbox"
                    checked={
                      Array.isArray(details.selectedOffenses) &&
                      details.selectedOffenses.includes(offense)
                    }
                    onChange={() => handleOffenseChange(offense)}
                    style={{ marginRight: "8px" }}
                  />
                  {offense}
                </label>
              ))}
            </div>
          )}
        </div>
      ))}

      {/* Text Field for Other Offense */}
      {Array.isArray(details.selectedOffenses) &&
        details.selectedOffenses.includes("Other") && (
          <input
            type="text"
            value={details.otherOffense}
            onChange={(e) => handleChange("otherOffense", e.target.value)}
            placeholder="Specify other offense"
            style={styles.input}
          />
        )}

      {/* Act Selection */}
      <div style={styles.formRow}>
        <label style={styles.label}>Act under which the Offense is Charged:</label>
        <select
          value={details.act}
          onChange={(e) => handleChange("act", e.target.value)}
          style={styles.select}
        >
          <option value="">Select Act</option>
          {actsList.map((act) => (
            <option key={act} value={act}>
              {act}
            </option>
          ))}
        </select>
        {details.act === "Other" && (
          <input
            type="text"
            value={details.otherAct}
            onChange={(e) => handleChange("otherAct", e.target.value)}
            placeholder="Specify other act"
            style={styles.input}
          />
        )}
      </div>

      {/* Cognizable or Non-Cognizable */}
      <div style={styles.formRow}>
        <label style={styles.label}>Are the offenses cognizable or non-cognizable?</label>
        <select
          value={details.cognizable}
          onChange={(e) => handleChange("cognizable", e.target.value)}
          style={styles.select}
        >
          <option value="cognizable">Cognizable</option>
          <option value="non-cognizable">Non-Cognizable</option>
        </select>
      </div>

      {/* Compoundable */}
      <div style={styles.formRow}>
        <label style={styles.label}>Are the offenses compoundable?</label>
        <select
          value={details.compoundable}
          onChange={(e) => handleChange("compoundable", e.target.value)}
          style={styles.select}
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
    </div>
  );
}